import * as React from 'react';
import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material';
import logo from "../assets/logo.png"
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useToken from '../providers/useToken';
import { Link, useNavigate } from 'react-router-dom';
import useStore from '../providers/useStore';

export const NavBar = () => {
  const removeToken = useToken((state) => state.removeToken)
  const infoToken = useStore((state) => state.infoToken)
  let navigate = useNavigate();
  
  const usuarios = () => {
    navigate("/usuarios");
  }

  const notas = () => {
    navigate("/nota");
  }

  return (
    <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ height: 72, background: "#D14343" }}
      >
        <Box sx={{ ml: 3, pl: 3 }}>
          <Link style={{textDecoration: 'none'}} to="/">
          <img
            alt="logo"
            src={logo}
            style={{
              display: "inline-block",
              width: "150px",
            }}
          /></Link>
        </Box>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ pr: 3 }}
          spacing={3}
        >
          { !infoToken.isAdmin && <Button onClick={notas} color='warning' variant="text" > Notas </Button> }
          { infoToken.isAdmin && <Button onClick={usuarios} color='warning' variant="text" > Usuários </Button> }
          <Tooltip title="Sair">
            <IconButton onClick={() => removeToken()} sx={{ color: "#fff" }} aria-label="Sair" component="label">
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
  );
}
