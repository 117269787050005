
import * as React from 'react';
import { NavBar } from '../components/NavBar';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import capa from "../assets/aulas.png"
import quiz from "../assets/quiz.png"
import { Navegacao } from '../components/Navegacao';
import { Title } from '../components/Title';
import { useNavigate, useParams } from 'react-router-dom';
import useFetchGetAula from '../queries/aula';
import { Loading } from '../components/Loading';
import { NoData } from '../components/NoData';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AddIcon from '@mui/icons-material/Add';
import useStore from '../providers/useStore';
import QuizIcon from '@mui/icons-material/Quiz';
import useFetchGetQuestionario from '../queries/questionario';
import usePlataforma from '../hooks/usePlataforma';

export const Aulas = () => {
  const { apagarAula, apagarQuestionario } = usePlataforma()
  const infoToken = useStore((state) => state.infoToken)
  const { id } = useParams()
  let navigate = useNavigate();

  const { isLoading, data } = useFetchGetAula(id)
  const { data: dataQuestionario } = useFetchGetQuestionario(id)


  const goVideo = (id) => {
    navigate(`/video/${id}`);
  }

  const goQuestionario = (id) => {
    navigate(`/questionario/${id}`);
  }

  const novaAula = () => {
    navigate("/adicionar/aula", { state: { moduloID: id }});
  }

  const novoQuiz = () => {
    navigate("/adicionar/questionario", { state: { moduloID: id }});
  }

  const temQuestinario = dataQuestionario && dataQuestionario.questionarios.length > 0

  return (
    <Box>
      <NavBar />
      <Navegacao voltar="Processos" voltarTo="-1" atual="Processo"/>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, px: 3 }}
      >
      <Title title="Lista de" strong="Processo"/>
      { infoToken.isAdmin &&
      <Stack
      direction="row"
      alignItems="center"
      spacing={2}

    > 
    <Button onClick={novaAula} variant="outlined" startIcon={<AddIcon />}> NOVO VÍDEO </Button>
    <Button onClick={novoQuiz} variant="outlined" startIcon={<AddIcon />}> NOVO QUIZ </Button>
    </Stack> }
      </Stack>
      <Loading isLoading={isLoading} />
      <NoData isEmpty={!isLoading && data && data.aulas.length === 0} />
      <Grid container spacing={3} sx={{p: 2}}>
      {data && data.aulas.length > 0 && data.aulas.map(current => (
        <Grid key={current._id} item xl={3} lg={3} sm={4} xs={12}>
        <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          alt="capa"
          sx={{ height: 140, cursor: 'pointer' }}
          image={capa}
          title={current.nome}
              onClick={() => goVideo(current._id)}
            >
              {current.assistida && <Chip
                label="Visto"
                size="small"
                icon={<DoneAllIcon color="success" />}
                sx={{
                  mt: 1,
                  ml: 1,
                  borderRadius: 1,
                  background: "#be454542",
                  border: 1,
                  borderColor: 'divider.main',
                  color: "#fff"
                }}
              />}
        </CardMedia>
         {Boolean(current.progress) && (<LinearProgress variant="determinate" value={current.progress} color="error"/>)}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
           {current.nome}
          </Typography>
          <Typography variant="body2" color="text.secondary">
          {current.descricao}
          </Typography>
        </CardContent>
        <CardActions sx={{ flexDirection: 'column' }}>
        <Button onClick={() => goVideo(current._id)} startIcon={<PlayCircleOutlineIcon />} fullWidth variant='contained' color='error' size="medium">ASSISTIR</Button>
        { infoToken.isAdmin && <Button sx={{mt: 1}} onClick={() => apagarAula(current._id)} fullWidth variant='text' color='error' size="small">Apagar</Button> }
      </CardActions>
      </Card>
        </Grid>
      ))}
      </Grid>
      { temQuestinario &&
      <>
<Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, px: 3 }}
      >
      <Title title="Lista de" strong="Questionários"/>
      </Stack>
      <Grid container spacing={3} sx={{p: 2}}>
      {dataQuestionario.questionarios.map(current => (
        <Grid key={current._id} item xl={3} lg={3} sm={4} xs={12}>
        <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          alt="capa"
          sx={{ height: 140, cursor: 'pointer' }}
          image={quiz}
          title={current.nome}
              onClick={() => goVideo(current._id)}
            />
        <CardContent>
          <Typography variant="body1" color="text.secondary">
          {current.nome}
          </Typography>
        </CardContent>
        <CardActions sx={{ flexDirection: 'column' }}>
        <Button onClick={() => goQuestionario(current._id)} startIcon={<QuizIcon />} fullWidth variant='contained' color='error' size="medium">VER</Button>
        { infoToken.isAdmin && <Button sx={{mt: 1}} onClick={() => apagarQuestionario(current._id)} fullWidth variant='text' color='error' size="small">Apagar</Button> }
      </CardActions>
      </Card>
        </Grid>
      ))}
      </Grid>
      </>
      }
      
    </Box>
  );
}
